import React from 'react';

import { useParameterEditorProps } from 'client/app/components/Parameters/ElementParameterGroupList';
import ParameterEditorWithHelperText from 'client/app/components/Parameters/ParameterEditor';
import { PlateContentParams } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import {
  BundleParameters,
  ElementInstance,
  Parameter,
  ParameterValue,
  WorkflowConfig,
} from 'common/types/bundle';

type Props = {
  element: ElementInstance;
  parameter: Parameter;
  allParameterValues: BundleParameters;
  plateContentParameters?: PlateContentParams;
  workflowConfig: WorkflowConfig;
  onChange: (param: ParameterValue) => void;
  isDisabled?: boolean;
};

export const ProtocolElementParameter = React.memo(
  ({
    element,
    parameter,
    plateContentParameters,
    allParameterValues,
    workflowConfig,
    onChange,
    isDisabled,
  }: Props) => {
    const editorProps =
      useParameterEditorProps(
        parameter,
        plateContentParameters,
        element.name,
        allParameterValues,
      ) ?? undefined;

    return (
      <ParameterEditorWithHelperText
        anthaType={parameter.type}
        parameter={parameter}
        onChange={(value, _) => onChange(value)}
        instanceName={element.name}
        editorType={parameter.configuration?.editor.type}
        editorProps={editorProps}
        workflowConfig={workflowConfig}
        placeholder={parameter.configuration?.editor.placeholder}
        value={allParameterValues[element.name][parameter.name]}
        isDisabled={isDisabled}
      />
    );
  },
);
