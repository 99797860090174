import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import EditableName from 'client/app/apps/protocols/EditableName';
import { ProtocolStepItem } from 'common/types/Protocol';

type Props<T extends ProtocolStepItem> = {
  items: T[];
  emptyMessage: string;
  onUpdate: (newContents: T[]) => void;
  onDelete?: (index: number) => void;
};

export default function StepContents<T extends ProtocolStepItem>({
  items,
  emptyMessage,
  onUpdate,
  onDelete,
}: Props<T>) {
  if (items.length === 0) {
    return (
      <Typography variant="body1" component="i">
        {emptyMessage}
      </Typography>
    );
  }

  const handleUpdateName = (index: number, newName: string) => {
    const newItems = items.toSpliced(index, 1, {
      ...items[index],
      displayName: newName,
    });
    onUpdate(newItems);
  };

  return (
    <StyledContentsWrapper variant="outlined">
      <List>
        {items.map((item, index) => {
          return (
            <ListItem key={item.id}>
              <EditableName
                name={item.displayName}
                onSave={newName => handleUpdateName(index, newName)}
                canDelete
                onDelete={() => onDelete?.(index)}
              />
            </ListItem>
          );
        })}
      </List>
    </StyledContentsWrapper>
  );
}

const StyledContentsWrapper = styled(Paper)({
  borderRadius: '4px',
});
