import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_DELETE_LIQUID_POLICY } from 'client/app/api/gql/mutations';
import { QUERY_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import PolicyEditorDialog from 'client/app/apps/policy-library/components/EditorDialog';
import PreviewPolicyDialog from 'client/app/apps/policy-library/components/PreviewDialog';
import PolicyLibraryAppNew from 'client/app/apps/policy-library/PolicyLibraryApp.new';
import PolicyLibraryAppOld from 'client/app/apps/policy-library/PolicyLibraryApp.old';
import DetailsView from 'client/app/components/Parameters/Policy/components/DetailsView';
import { LiquidPolicy } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function PolicyLibraryScreen() {
  const isEnabledLiquidPolicyLibrary = useFeatureToggle('LIQUID_POLICY_LIBRARY');

  const [policy, setPolicy] = useState<LiquidPolicy | undefined>();

  const { deleteLiquidPolicy } = useCardMenuHandlers();

  return isEnabledLiquidPolicyLibrary ? (
    <>
      <PolicyLibraryAppNew onViewDetails={setPolicy} onDelete={deleteLiquidPolicy} />
      {policy && (
        <PreviewPolicyDialog policy={policy} onClose={() => setPolicy(undefined)}>
          <DetailsView policy={policy} />
        </PreviewPolicyDialog>
      )}
      <PolicyEditorDialog />
    </>
  ) : (
    <PolicyLibraryAppOld />
  );
}

function useCardMenuHandlers() {
  const { showSuccess, showError } = useSnackbarManager();
  const [callDeletePolicy] = useMutation(MUTATION_DELETE_LIQUID_POLICY);
  const deleteLiquidPolicy = useCallback(
    async (policy: LiquidPolicy) => {
      await callDeletePolicy({
        variables: {
          id: policy.id,
        },
        refetchQueries: [QUERY_LIQUID_POLICIES],
        onCompleted() {
          showSuccess(`Successfully deleted liquid policy "${policy.name}"`);
        },
        onError(error) {
          showError(error.message);
        },
      });
    },
    [callDeletePolicy, showError, showSuccess],
  );
  return {
    deleteLiquidPolicy,
  };
}
