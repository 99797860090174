import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  ProtocolUpdate,
  useUpdateProtocol,
} from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ProtocolQuery, Simulation } from 'client/app/gql';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { Markdown } from 'common/lib/markdown';
import { ErrorCodes } from 'common/types/errorCodes';

type ProtocolContextType = {
  name: string;
  displayDescription: Markdown;
  shortDescription: string;
  updateProtocol: (values: ProtocolUpdate) => void;
  conflictDialog: JSX.Element | null;
  exampleSimulation: Pick<
    Simulation,
    'status' | 'id' | 'actionsLayoutFiletreeLink'
  > | null;
};

export const ProtocolContext = createContext<ProtocolContextType | undefined>(undefined);

type ProtocolProviderProps = {
  entity: NonNullable<ProtocolQuery['protocol']['entity']>;
  version: ProtocolVersion;
} & PropsWithChildren;

export const useProtocolContext = () => {
  const context = useContext(ProtocolContext);

  if (context === undefined) {
    throw new Error('useProtocolContext must be used within a ProtocolProvider');
  }

  return context;
};

export const ProtocolProvider: FC<ProtocolProviderProps> = ({
  entity,
  version,
  children,
}) => {
  const {
    id: protocolId,
    name,
    editVersion,
    shortDescription,
    exampleSimulation,
    protocol: { displayDescription },
  } = entity;

  const { handleUpdateProtocol, loading } = useUpdateProtocol(protocolId, version);
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();
  const [protocolUpdate, setProtocolUpdate] = useState<ProtocolUpdate>({});
  const [updateProtocolRequired, setUpdateProtocolRequired] = useState(false);

  useEffect(() => {
    (async () => {
      if (loading || !updateProtocolRequired || conflictDialog) return;

      setUpdateProtocolRequired(false);
      try {
        await handleUpdateProtocol(editVersion, protocolUpdate);
      } catch (error) {
        await handleCheckConflictError(
          error,
          'protocol',
          ErrorCodes.PROTOCOL_EDIT_CONFLICT,
        );
      }
    })();
  }, [
    conflictDialog,
    editVersion,
    handleCheckConflictError,
    handleUpdateProtocol,
    loading,
    protocolUpdate,
    updateProtocolRequired,
    setUpdateProtocolRequired,
  ]);

  const updateProtocol = useCallback((values: ProtocolUpdate) => {
    setProtocolUpdate(values);
    setUpdateProtocolRequired(true);
  }, []);

  const state = {
    name,
    shortDescription,
    displayDescription,
    updateProtocol,
    conflictDialog,
    exampleSimulation,
  };

  return <ProtocolContext.Provider value={state}>{children}</ProtocolContext.Provider>;
};
