import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import {
  OutputPreview,
  OutputPreviewSkeleton,
} from 'client/app/apps/workflow-builder/output-preview/OutputPreview';
import LabBenchBanner from 'common/assets/LabBenchBanner';
import Colors from 'common/ui/Colors';

type OutputStepPreviewProps = {
  noOutputCallToAction?: JSX.Element;
};

export const OutputStepPreview = ({ noOutputCallToAction }: OutputStepPreviewProps) => {
  const { selectedStep } = useStepsContext();
  const noOutput = !!selectedStep && selectedStep.outputs.length === 0;

  return (
    <Wrapper noOutput={noOutput}>
      <OutputPreviewTitle variant="h4">Preview Output</OutputPreviewTitle>
      {noOutput ? (
        <NoOutputPreviewWrapper>
          <LabBenchBanner sx={{ width: '684px', height: '382px' }} />
          <Typography variant="h3">This step has no outputs to preview.</Typography>
          {noOutputCallToAction}
        </NoOutputPreviewWrapper>
      ) : (
        <OutputPreviewWrapper>
          <OutputPreview />
        </OutputPreviewWrapper>
      )}
    </Wrapper>
  );
};

export function OutputStepPreviewSkeleton() {
  return (
    <Wrapper noOutput={false}>
      <OutputPreviewTitle variant="h4">Preview Output</OutputPreviewTitle>
      <OutputPreviewSkeleton />
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'noOutput',
})<{ noOutput: boolean }>(({ theme, noOutput }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(0, 3, 3, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderLeft: 'none',
  backgroundColor: noOutput ? Colors.GREY_10 : 'white',
  justifyContent: noOutput ? 'center' : 'normal',
  width: '100%',
}));

const NoOutputPreviewWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '95%',
  gap: theme.spacing(8),
  overflowX: 'clip',
}));

const OutputPreviewTitle = styled(Typography)(() => ({
  fontWeight: 600,
}));

const OutputPreviewWrapper = styled('div')({
  height: '95%',
});
