import React, { useCallback, useEffect, useMemo } from 'react';

import Input from '@mui/material/Input';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import EditableName, { Name } from 'client/app/apps/protocols/EditableName';
import { ProtocolElementParameter } from 'client/app/apps/protocols/ProtocolElementParameter';
import { getPlateContentParams } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { ParameterValue } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  updateParams?: (schemaInputId: string, value: any) => void;
  isEditable?: boolean;
};

export const InputStep = ({ isEditable, updateParams }: Props) => {
  const { selectedStep } = useStepsContext();
  const {
    config: workflowConfig,
    getSchemaParameter,
    updateOutput,
    parameters,
  } = useWorkflowContext();

  const selectedInputParameters = useMemo(() => {
    const inputs = selectedStep?.inputs || [];
    return inputs.flatMap(input => {
      const { element, parameter } = getSchemaParameter(input.id);
      if (element && parameter) {
        const configuration = input.configuration && {
          editor: input.configuration,
          isVisible: true,
          displayDescription: input.displayDescription, // TODO - Is this the right display description?
          displayName: input.displayName,
        };
        const selectedInput = {
          id: input.id,
          element,
          parameter: {
            ...parameter,
            description: input.displayDescription,
            configuration,
          },
        };
        return [selectedInput];
      }
      return [];
    });
  }, [getSchemaParameter, selectedStep?.inputs]);

  const handleParameterChange = useCallback(
    (schemaInputId: string) => (value: ParameterValue) =>
      updateParams?.(schemaInputId, value),
    [updateParams],
  );

  useEffect(() => {
    selectedStep?.outputs.length && updateOutput(selectedStep.outputs[0].id);
  }, [selectedStep?.outputs, updateOutput]);

  return (
    <Wrapper>
      <InputStepName variant="h4">{selectedStep?.displayName}</InputStepName>
      <ContainerWithIntersectionBar
        dense
        noHeader
        content={
          <Stack spacing={5}>
            {selectedInputParameters.map(({ id: inputId, parameter, element }) => {
              return (
                <Stack spacing={4} key={inputId}>
                  {isEditable ? (
                    <EditableName
                      sx={{ fontWeight: 500, fontSize: '14px' }}
                      name={parameter.name}
                      onSave={() => {}}
                      canDelete={false}
                      onDelete={() => {}}
                    />
                  ) : (
                    <Name sx={{ fontWeight: 500, fontSize: '14px' }}>
                      {parameter.name}
                    </Name>
                  )}
                  {isEditable ? (
                    <EditableParameterDescription
                      multiline
                      disableUnderline
                      value={parameter.description}
                      placeholder="+ Add description"
                      onChange={() => {}}
                    />
                  ) : (
                    parameter.description && (
                      <ParameterDescription>{parameter.description}</ParameterDescription>
                    )
                  )}
                  <ProtocolElementParameter
                    element={element}
                    parameter={parameter}
                    allParameterValues={parameters}
                    plateContentParameters={getPlateContentParams(element.element.inputs)}
                    onChange={handleParameterChange(inputId)}
                    workflowConfig={workflowConfig}
                    isDisabled={updateParams === undefined}
                  />
                </Stack>
              );
            })}
          </Stack>
        }
      />
    </Wrapper>
  );
};

export function InputStepSkeleton() {
  const { selectedStep } = useStepsContext();
  return (
    <Wrapper>
      <InputStepName variant="h4">
        {selectedStep?.displayName || 'New Step'}
      </InputStepName>
      <Stack spacing={5}>
        <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
        <Skeleton sx={{ mb: 5 }} variant="rounded" width="100%" height={50} />
        <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '376px',
  maxWidth: '376px',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
  borderRadius: theme.spacing(3, 0, 0, 3),
  border: `1px solid ${Colors.GREY_30}`,
  backgroundColor: 'white',
}));

const InputStepName = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const EditableParameterDescription = styled(Input)(({ theme }) => ({
  '&.Mui-focused, &:hover': {
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: theme.spacing(3),
    fontSize: '14px',
  },
  fontWeight: 300,
}));

const ParameterDescription = styled(Typography)({
  whiteSpace: 'pre-line',
  fontWeight: 300,
  fontSize: '14px',
});
