import { useEffect, useState } from 'react';

import PlateContentsEditorDialog from 'client/app/components/Parameters/PlateContents/PlateContentsEditorDialog';
import PlateLayoutEditorDialog from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorDialog';
import {
  ElementErrorData,
  useElementErrorData,
} from 'client/app/components/ValidationIndicator/ValidationIndicator';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { groupBy } from 'common/lib/data';
import { ProtocolStep } from 'common/types/Protocol';
import useDialog from 'common/ui/hooks/useDialog';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

/**
 * Handles the opening and closing of complex parameter editor dialogs.
 * Relies on triggers in worklfowBuilderStateContext. This does now allow
 * modifying the content of any of the dialogs, only the rendering of them.
 *
 * @returns Array of dialog JSX elements to render
 */
export function useComplexParameterEditorDialogManager() {
  // Currently when a dialog is triggered, then the state for additionalPanel is set
  // to be the value of the dialog to open. This was built to be used within the Builder
  // component. Here, we are re-using that logic to determine when to open one of the
  // complex parameter dialogs, because the underlying trigger components (i.e. buttons)
  // we are using in Protocols are the same as those in the Builder.
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const dispatch = useWorkflowBuilderDispatch();

  const [plateContentsEditorDialog, openPlateContentsEditorDialog] = useDialog(
    PlateContentsEditorDialog,
  );
  const [plateLayoutEditorDialog, openPlateLayoutEditorDialog] = useDialog(
    PlateLayoutEditorDialog,
  );

  useEffect(() => {
    const resetPanel = () => {
      dispatch({ type: 'setAdditionalPanel', payload: undefined });
    };
    const handleOpenDialog = async () => {
      switch (additionalPanel) {
        case 'PlateContentsEditor':
          await openPlateContentsEditorDialog({});
          resetPanel();
          break;
        case 'PlateLayoutEditor':
          await openPlateLayoutEditorDialog({});
          resetPanel();
          break;
      }
    };

    void handleOpenDialog();
  }, [
    additionalPanel,
    dispatch,
    openPlateContentsEditorDialog,
    openPlateLayoutEditorDialog,
  ]);

  return [plateContentsEditorDialog, plateLayoutEditorDialog];
}

const PROTOCOL_SELECTED_STEP_ID_PARAM = 'selected_step';
const PROTOCOL_EXPANDED_LIST_PARAM = 'expand_input_list';

/**
 * Handles storing state in URL for parameters relating to the Protocols UI.
 *
 * @returns State setters for each URL param
 */
export function useProtocolsParamState(steps: ProtocolStep[]): {
  selectedStep?: ProtocolStep;
  handleSelectStep: (step: ProtocolStep) => void;
  expandInputList?: boolean;
  handleSetExpandInputList: () => void;
} {
  const [selectedStepId, setSelectedStepId] = useStateWithURLParams({
    paramName: PROTOCOL_SELECTED_STEP_ID_PARAM,
    paramType: 'string',
  });

  const [expandInputList, setExpandInputList] = useStateWithURLParams({
    paramName: PROTOCOL_EXPANDED_LIST_PARAM,
    paramType: 'boolean',
  });

  const handleSelectStep = (step: ProtocolStep) => {
    setSelectedStepId(step.id);
  };

  const handleSetExpandInputList = () => {
    setExpandInputList(!expandInputList);
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  // if the steps change and no longer contains the selectedStepId gracefully
  // fallback to the closest step
  useEffect(() => {
    if (steps.length === 0) {
      return;
    }
    const index = steps.findIndex(({ id }) => id === selectedStepId);
    if (index > -1) {
      setSelectedIndex(index);
      return;
    }
    // ensure index and step id are matched to prevent infinite loops
    const fallbackIndex = selectedIndex - 1 > -1 ? selectedIndex - 1 : 0;
    setSelectedIndex(fallbackIndex);
    setSelectedStepId(steps[fallbackIndex].id);
  }, [steps, selectedStepId, selectedIndex, setSelectedStepId]);

  return {
    selectedStep: steps[selectedIndex],
    handleSelectStep,
    expandInputList,
    handleSetExpandInputList,
  };
}

export function useStepErrors(steps: ProtocolStep[]): {
  [stepId: string]: ElementErrorData[];
} {
  const errors = useElementErrorData();
  const errorsByElementId = groupBy(errors, 'elementId');
  const result = steps.map<[string, ElementErrorData[]]>(step => {
    const stepElementIds = [
      ...new Set(step.inputs.map(({ elementInstanceId }) => elementInstanceId)),
    ];
    const stepErrors = stepElementIds.flatMap(id => errorsByElementId[id] || []);
    return [step.id, stepErrors];
  });
  return Object.fromEntries(result);
}
