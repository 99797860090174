import React, { useCallback, useRef, useState } from 'react';

import Add from '@mui/icons-material/Add';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { usePolicyDialogState } from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import {
  conditionOptions,
  consequenceOptions,
} from 'client/app/apps/policy-library/components/ruleTableConfig';
import { RuleMetaKey, RuleMetaType } from 'client/app/apps/policy-library/RulesMeta';
import LiquidPolicyIntercomLink from 'client/app/components/Parameters/Policy/components/IntercomLink';
import { DataTable } from 'common/types/spreadsheetEditor';
import Colors from 'common/ui/Colors';
import SimpleDialog from 'common/ui/components/Dialog/SimpleDialog';
import Table from 'common/ui/components/Table';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import useDialog from 'common/ui/hooks/useDialog';

export default function RulesTab() {
  const [
    {
      policy: {
        rules: { dataTable, tableConfig, tableState },
      },
    },
    dispatch,
  ] = usePolicyDialogState();

  const handleChange = useCallback(
    (newData: DataTable) => {
      dispatch({
        type: 'update_table',
        payload: newData,
      });
    },
    [dispatch],
  );

  const [open, setOpen] = useState(false);
  const [dialog, openDialog] = useDialog(DropdownDialog);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const addColumn = async (type: RuleMetaType, options: Option<RuleMetaKey>[]) => {
    setOpen(true);

    await openDialog({
      type,
      options,
      onSubmit(newValue: RuleMetaKey) {
        dispatch({
          type: 'add_column',
          payload: {
            type,
            name: newValue,
          },
        });
        setOpen(false);

        // Scroll to the right automatically after the column is added
        setTimeout(() => {
          tableContainerRef.current?.scrollTo({
            left: tableContainerRef.current.scrollWidth,
            behavior: 'smooth',
          });
        });
      },
      onCancel() {
        setOpen(false);
      },
    });
  };
  const addRow = () => {
    dispatch({
      type: 'add_row',
      payload: dataTable.data.length,
    });
  };

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={2} justifyContent="flex-end">
        <Button
          variant="text"
          color="inherit"
          startIcon={<Add />}
          onClick={() => addColumn('condition', conditionOptions)}
        >
          Add condition
        </Button>
        <Button
          variant="text"
          color="inherit"
          startIcon={<Add />}
          onClick={() => addColumn('consequence', consequenceOptions)}
        >
          Add consequence
        </Button>
      </Stack>
      <TableContainer ref={ref => (tableContainerRef.current = ref)}>
        <Table
          dataTable={dataTable}
          configuration={tableConfig}
          state={tableState}
          noHeaderMask
          onDataTableChange={handleChange}
        />
      </TableContainer>
      <AddRuleButton
        variant="text"
        color="inherit"
        startIcon={<Add />}
        onClick={() => addRow()}
      >
        Add rule
      </AddRuleButton>
      <HowRulesWork />
      {open && dialog}
    </Stack>
  );
}

const RULE_PROP_DESCRIPTIONS: Record<RuleMetaType, string> = {
  condition:
    'Conditions are optional and constrain which types of liquid transfers should be affected by a liquid policy rule.',
  consequence:
    'Consequences determine the liquid handling preferences for a liquid policy rule',
};

function DropdownDialog({
  type,
  options,
  onSubmit,
  onCancel: handleClose,
}: {
  type: RuleMetaType;
  options: Option<RuleMetaKey>[];
  onSubmit: (value: RuleMetaKey) => void;
  onCancel: () => void;
}) {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSubmit = () => {
    onSubmit(selectedOption.value);
  };

  return (
    <SimpleDialog
      isOpen
      title={`Add ${type}`}
      contentText={RULE_PROP_DESCRIPTIONS[type]}
      content={
        <Stack mt={4}>
          <Dropdown
            options={options}
            valueLabel={selectedOption.label}
            onChange={value => {
              const newOption = options.find(option => option.value === value);
              if (newOption) {
                setSelectedOption(newOption);
              }
            }}
          />
        </Stack>
      }
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
}

const HowRulesWork = React.memo(() => (
  <Stack gap={3} mt={6}>
    <Stack direction="row" gap={2}>
      <InfoOutlined fontSize="small" style={{ color: Colors.TEXT_SECONDARY }} />
      <Typography variant="h6" color="textSecondary">
        How rules work
      </Typography>
    </Stack>
    <List>
      <ListItem>
        Rules define how a selected liquid is handled by the liquid handling arm during
        experiments.
      </ListItem>
      <ListItem>
        Synthace has some general liquid handling rules to optimise behaviour.
      </ListItem>
      <ListItem>You can then define and add a liquid policy with its own rules.</ListItem>
      <ListItem>
        Each rule will be considered in turn, meaning Rule 1 can be overwritten by Rule 3
        if you choose the same conditions for them.
      </ListItem>
      <ListItem>
        Learn more about rules <LiquidPolicyIntercomLink />.
      </ListItem>
    </List>
  </Stack>
));

const List = styled('ul')({
  margin: 0,
});

const ListItem = styled('li')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 1.6,
  color: theme.palette.text.secondary,
}));

const TableContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  padding: theme.spacing(3, 0),
}));

const AddRuleButton = styled(Button)({
  alignSelf: 'flex-start',
});
