import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ProtocolsQuery } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';
import { ExampleWorkflowIcon } from 'common/ui/icons';

type Props = {
  data: ProtocolsQuery;
};

const ProtocolsListDataComponent = ({ data }: Props) => {
  const protocols = data.protocols.items || [];

  return (
    <List>
      {protocols.map(protocol => (
        <StyledEntityCard
          key={protocol.id}
          icon={<ExampleWorkflowIcon />}
          nameColumn={{ value: protocol.shortDescription, label: protocol.name }}
        />
      ))}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

const StyledEntityCard = styled(EntityCard)(({ theme }) => ({
  '.EntityCardContent-nameColumn-label': {
    ...theme.typography.subtitle2,
  },
  '.EntityCardContent-nameColumn-value': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));

export default ProtocolsListDataComponent;
